export default {
  service: {
    exploit: 'http://127.0.0.1:5001/', // 开发环境
    test: 'http://web.sirli.cn:5001/', // 测试环境
    produce: 'https://api.huilianyingyu.com/' // 生产环境
  }, // 服务地址
  environment: 'produce', // 环境
  isDebug: true, // 输出请求结果
  userInfo: {
    id: '',
    tele: '',
    token: '',
    expiration: '', // 过期时间
    head: '', // 头像
    nickname: '', // 昵称
    route: 'China', // 线路
    isAuthorization: false // 是否已授权
  } // 用户信息
}
