import request from '@/utils/request'
import Vue from 'vue'

// 获取视频
export function getVideo(data) {
  return request({
    url: 'Video/GetVideoCdn',
    data,
    method: 'POST'
  })
}

// 获取分组视频列表（附带学员学习信息）
export function getVideoList(data) {
  return request({
    url: 'Video/GetVideoList',
    data,
    method: 'POST'
  })
}

// 获取分组视频列表（不附带学员学习信息）
export function getVideoListNot(data) {
  return request({
    url: 'Video/GetVideoListNot',
    data,
    method: 'POST'
  })
}

// 视频截帧
export function videoSectional(data) {
  return request({
    url: 'Video/VideoSectional',
    params: data,
    method: 'GET'
  })
}

// 播放进程记录
export function addPlayProcess(data) {
  var userInfo = JSON.parse(localStorage.getItem('user'))
  userInfo.learningTime += 5
  localStorage.setItem('user', JSON.stringify(userInfo))
  Vue.$setUserInfo()
  return request({
    url: 'Video/AddPlayProcess',
    params: data,
    method: 'GET'
  })
}

// 获取情景视频
export function sceneVideo(data) {
  return request({
    url: 'Video/SceneVideo',
    params: data,
    method: 'GET'
  })
}

// 单词库收录
export function addWord(data) {
  return request({
    url: 'Video/AddWord',
    params: data,
    method: 'GET'
  })
}

// 学习资源（美剧）
export function usTvResource(data) {
  return request({
    url: 'Video/UsTvResource',
    params: data,
    method: 'GET'
  })
}

// 搜索视频
export function search(data) {
  return request({
    url: 'Video/Search',
    params: data,
    method: 'GET'
  })
}

// 视频收藏列表
export function avCollectList(data) {
  return request({
    url: 'Video/AvCollectList',
    data,
    method: 'POST'
  })
}

// 按名称搜索视频（多情景单词/多情景语块）
export function getAvName(data) {
  return request({
    url: 'Video/GetAvName',
    params: data,
    method: 'GET'
  })
}

// 搜索美音视频
export function searchMeiYin(data) {
  return request({
    url: 'Video/SearchMeiYin',
    params: data,
    method: 'GET'
  })
}
