import axios from 'axios'
import router from '@/router'
import {
  MessageBox,
  Notification
} from 'element-ui'
import {
  USER_TOKEN
} from '@/utils/constant'
import Vue from 'vue'
import md5 from 'js-md5'
import conf from './../utils/conf'
var code401 = false

// create an axios instance
const service = axios.create({
  baseURL: conf.service[conf.environment], // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = localStorage.getItem(USER_TOKEN)
    if (token) {
      // TODO 判断指定页面不需要进行Token console.info(config)
      config.headers['Authorization'] = `Bearer ${token}`
    }
    if (localStorage.getItem('user') !== null) {
      var userInfo = JSON.parse(localStorage.getItem('user'))
      // 随机数生成
      var nonce = Math.floor(Math.random() * 100000000 + 1)
      // 请求时间戳
      var timestamp = Date.parse(new Date()) * 1000
      // 链接请求头各项数据
      var signStr = timestamp + '' + userInfo.id + '' + nonce + '' + userInfo.token
      // 按字符拆分位组
      var sortStr = signStr.split('')
      // 排序
      sortStr = sortStr.sort().join('')

      // 用户id
      config.headers['id'] = userInfo.id
      config.headers['nonce'] = nonce
      config.headers['timestamp'] = timestamp
      config.headers['signature'] = md5(sortStr)
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // 处理http正常返回的情况
    if (res.code !== 200) {
      // 非法令牌；50012:其他客户端登录；50014:令牌过期；
      switch (res.code) {
        case 401:
          if (!code401) {
            code401 = true
            // to re-login
            MessageBox.confirm('您已注销，您可以取消停留在该页上，或再次登录', '确认注销', {
              confirmButtonText: '重新登录',
              closeOnClickModal: false,
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // TODO 跳转到登录页面
              localStorage.removeItem('Tele')
              localStorage.removeItem('LoginPass')
              localStorage.removeItem('user')
              Vue.$conf.userInfo.uid = ''
              if (window.location.href.indexOf('index') !== -1) {
                window.location.reload()
              } else {
                router.push('/login')
                // router.push({
                //   name: 'Login'
                // })
              }
              code401 = false
            })
          }
          break
          // 请求接口验证无效
        case 400:
          Notification.warning({
            title: '温馨提示',
            dangerouslyUseHTMLString: true,
            message: res.msg
          })
          break
        default:
          Notification.error({
            message: res.msg || '服务器繁忙，请稍候重试',
            title: '错误'
          })

          break
      }
      // 直接将错误信息全部驳回，方便页面调取
      return Promise.reject(res)
    } else {
      return res
    }
  },
  ex => {
    // 处理http异常
    let msg = '服务接口未启动，请稍后重试'
    if (ex.response) {
      // console.log(ex.response.message) // for debug
      switch (ex.response.status) {
        case 405:
          msg = '服务接口请求方式不正确(GET/POST)'
          break
        case 415:
          msg = '服务接口不支持请求方式(JSON/FORM)'
          break
        case 404:
          msg = '服务接口不存在'
          break
        case 500:
          msg = '服务异常'
          break
      }
      Notification.error({
        message: msg,
        title: '错误'
      })
    }
    console.log(ex)

    return Promise.reject(msg)
  }
)

export default service
