// 路由信息
export const ROUTER_INFO = 'Amm.English_Route'

// 用户凭证
export const USER_TOKEN = 'Amm.English_UserToken'

// 初始化当前路径
export const INIT_LINK = 'Amm.English_Route'

// 待支付订单号
export const PAYMENT_ORDER_NO = 'Amm.English_OrderNo'
