import request from '@/utils/request'

// 上传文件（小文件）
export function upMinLoading(data) {
  return request({
    url: 'sys/UpMinLoading',
    data,
    method: 'POST'
  })
}

// 添加问题反馈
export function addError(data) {
  return request({
    url: 'sys/AddError',
    data,
    method: 'POST'
  })
}

// 获取系统快捷键
export function sysKey(data) {
  return request({
    url: 'Sys/SysKey',
    params: data,
    method: 'GET'
  })
}

// 有道翻译 html清洗
export function youDaoHtml(data) {
  return request({
    url: 'Sys/YouDaoHtml',
    params: data,
    method: 'GET'
  })
}

// 获取字典列表
export function getDictionariesList(data) {
  return request({
    url: 'Sys/GetDictionariesList',
    params: data,
    method: 'GET'
  })
}

// 微信登录地址
export function weChatLogin(data) {
  return request({
    url: 'Sys/WeChatLogin',
    params: data,
    method: 'GET'
  })
}

// 微信授权登录验证
export function weChatVerify(data) {
  return request({
    url: 'Sys/WeChatVerify',
    params: data,
    method: 'GET'
  })
}

// 获取手机验证码
export function smsCode(data) {
  return request({
    url: 'Sys/SmsCode',
    data,
    method: 'POST'
  })
}

// 百度翻译
export function baiduTranslate(data) {
  return request({
    url: 'Sys/BaiduTranslate',
    data,
    method: 'POST'
  })
}

// 有道翻译
export function youDaoTranslate(data) {
  return request({
    url: 'Sys/YouDaoTranslate',
    params: data,
    method: 'get'
  })
}

// Html清洗
export function kls(data) {
  return request({
    url: 'Html/Kls',
    data,
    method: 'post'
  })
}

// 翻译狗翻译（词）
export function fygTranslate(data) {
  return request({
    url: 'Sys/FygTranslate',
    params: data,
    method: 'get'
  })
}
