import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import conf from './utils/conf.js'
import './icons' // icon
// 使用自定义element ui 样式
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/theme/index.css'
import * as echarts from 'echarts'
import './utils/drag'
// 设置元素的大小
Vue.use(Element, {
  size: 'mini',
  zIndex: 3000
})
Vue.$conf = conf
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
