import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: 'User/Login',
    data,
    method: 'POST'
  })
}

// 绑定微信
export function bindingWeChat(data) {
  return request({
    url: 'User/BindingWeChat',
    params: data,
    method: 'GET'
  })
}

// 设置页数据
export function setPage(data) {
  return request({
    url: 'User/SetPage',
    params: data,
    method: 'GET'
  })
}

// 忘记密码
export function forgotPassword(data) {
  return request({
    url: 'User/ForgotPassword',
    params: data,
    method: 'GET'
  })
}

// 用户权限
export function userRole(data) {
  return request({
    url: 'User/UserRole',
    params: data,
    method: 'GET'
  })
}

// 设置访问线路
export function setRoute(data) {
  return request({
    url: 'User/SetRoute',
    params: data,
    method: 'GET'
  })
}

// 视频收藏
export function avCollect(data) {
  return request({
    url: 'User/AvCollect',
    params: data,
    method: 'GET'
  })
}

// 视频是否收藏
export function isAvCollect(data) {
  return request({
    url: 'User/IsAvCollect',
    params: data,
    method: 'GET'
  })
}

