<template>
  <div id="app">
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style >
html,body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
<style lang="less">
#app{
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: url("assets/background.jpg") no-repeat center fixed;
  background-size: cover;
}
</style>
