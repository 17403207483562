import Vue from 'vue'
import VueRouter from 'vue-router'
import getPageTitle from '../utils/page-title'
/* Layout */
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/index',
    component: Layout,
    children: [{
      path: 'index',
      component: () => import('@/views/index.vue'),
      name: 'Index',
      meta: { title: '首页' }
    },
    {
      path: 'audioreading',
      name: 'Audioreading',
      component: () => import('@/views/audioreading.vue'),
      meta: {
        title: '有声阅读'
      }
    },
    {
      path: 'readingplayer',
      name: 'Readingplayer',
      component: () => import('@/views/readingplayer.vue'),
      meta: {
        title: '有声阅读播放器'
      }
    },
    {
      path: 'player',
      name: 'Player',
      component: () => import('@/views/player.vue'),
      meta: {
        title: '播放器'
      }
    },
    {
      path: 'notebook',
      name: 'Notebook',
      component: () => import('@/views/notebook.vue'),
      meta: {
        title: '生词本'
      }
    },
    {
      path: 'statistics',
      name: 'Statistics',
      component: () => import('@/views/statistics.vue'),
      meta: {
        title: '词频统计'
      }
    },
    {
      path: 'reading',
      name: 'Reading',
      component: () => import('@/views/reading.vue'),
      meta: {
        title: '阅读'
      }
    },
    {
      path: 'sentence',
      name: 'Sentence',
      component: () => import('@/views/sentence.vue'),
      meta: {
        title: '例句查询'
      }
    },
    {
      path: 'cartoon',
      name: 'Cartoon',
      component: () => import('@/views/cartoon.vue'),
      meta: {
        title: '绘本阅读器'
      }
    },
    {
      path: 'palylog',
      name: 'Palylog',
      component: () => import('@/views/palylog.vue'),
      meta: {
        title: '学习记录'
      }
    },
    {
      path: 'avcollect',
      name: 'AvCollect',
      component: () => import('@/views/avcollect.vue'),
      meta: {
        title: '视频收藏'
      }
    },
    {
      path: 'noaccess',
      name: 'Noaccess',
      component: () => import('@/views/noaccess.vue'),
      meta: {
        title: '无权访问'
      }
    }]
  },
  {
    path: '/login',
    name: '/Login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/test',
    name: '/Test',
    component: () => import('@/views/test.vue'),
    meta: {
      title: '测试页面'
    }
  } //,
  // {
  //   path: '/playlist',
  //   name: '/Playlist',
  //   component: () => import('@/views/playlist_old.vue'),
  //   meta: {
  //     title: '播放列表'
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // set page title
  document.title = getPageTitle(to.meta.title)

  next()
})

router.afterEach(() => {

})

export default router
