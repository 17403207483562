<template>
  <el-container class="layout" :class="{'layout-screen':isScreen }">
    <el-header v-if="!isScreen" class="header" style="height: 45px;">
      <div class="menu" @click="asideWidth=!asideWidth;onMenuShow()">
        <img src="../assets/images/home/菜单.png" />
      </div>
      <div class="logo">
        <el-image class="image" :src="require('@/assets/logo.png')" fit="fit" @click="onHome()" />
        <span style="color:#B62B1A;">好英语，练出来！</span>
        <div class="type row"></div>
      </div>
      <!-- 右侧按钮 -->
      <div class="right">
        <!-- 线路 -->
        <div
          class="row"
          style="font-size:12px; display: flex;justify-content: center;align-items: center;"
        >
          <label style="margin-right: 10px;">线路</label>
          <el-select
            v-model="route"
            style="width: 80px;margin-right: 10px;"
            @change="onRouteChange"
          >
            <el-option label="中国" value="China" />
            <el-option label="美国" value="America" />
          </el-select>
        </div>
        <!-- 首页 -->
        <div class="nav">
          <img src="../assets/images/home/主页.png" style="width:18px;" @click="onHome()" />
        </div>
        <!-- 搜索 -->
        <div class="search">
          <el-button
            v-if="!searchShow"
            slot="reference"
            icon="el-icon-search"
            circle
            @click="searchShow=true"
          />
          <el-popover v-else v-model="visible" placement="bottom" width="540" trigger="manual">
            <el-input
              slot="reference"
              v-model="search"
              placeholder="请输入内容"
              class="input-with-select"
              @keyup.enter.native="onSearch();visible = true;"
              @focus="onSearchFocus()"
              @blur="onSearchBlur()"
            >
              <el-button slot="append" icon="el-icon-search" @click="visible = true;onSearch();" />
            </el-input>
            <div>
              <div>
                <span style="font-size:12px;margin-left: 0px;">搜索结果</span>
                <i
                  style="position: absolute;z-index: 1;right: 10px;"
                  class="el-icon-close"
                  @click="visible = false;searchShow=false"
                ></i>
              </div>

              <div
                style="background: rgb(247 247 247);border-radius: 6px;padding-top: 0px;margin-top: 10px;"
                :style="{height:searchHeight + 'px'}"
              >
                <div
                  v-if="searchVideoList.length==0"
                  style="height:100%;  display: flex;justify-content: center;align-items: center;"
                >无数据</div>
                <el-scrollbar v-else class="scrollbar" :style="{height:(searchHeight - 0) + 'px'}">
                  <div class="row video-list">
                    <div
                      v-for="(video) in searchVideoList"
                      :key="video.vid"
                      style="margin:10px;height: 100%;"
                      @click="visible = false;searchShow=false;onVideoClick(video.id)"
                    >
                      <div class="column video">
                        <el-image
                          v-if=" video.url.includes('mp3')"
                          class="video-img"
                          fit="cover"
                          :src="require('@/assets/mp3-logo.jpg')"
                          lazy
                        />
                        <el-image v-else class="video-img" fit="cover" :src="video.cover" lazy />
                        <div class="column content">
                          <span class="video-name">{{ video.name }}</span>
                          <span class="video-tag">{{ video.tag }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </div>
          </el-popover>
        </div>
        <!-- 学习时间 -->
        <div v-if="jurisdiction.functions['lt']" class="time">
          <div class="column time-ioc">
            <img src="../assets/images/home/学习时间.png" style="width:18px;" />
          </div>
          <span>{{ Math.floor(setData.day/60/60) }}小时{{ (Math.floor(setData.day/60)%60) }}分钟</span>
        </div>
        <!-- 本地视频、字幕  -->
        <div v-if="jurisdiction.functions['lvs']" class="nav column" title="打开本地视频、字幕" @click="isCustomVideoCaptions=true">
          <svg
            t="1634784080682"
            class="icon"
            viewBox="0 0 1264 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2415"
            width="30"
            height="30"
          >
            <path
              d="M992.171444 312.62966C975.189616 137.155482 827.415189 0 647.529412 0 469.849434 0 323.616239 133.860922 303.679205 306.210218 131.598564 333.839271 0 482.688318 0 662.588235c0 199.596576 161.815189 361.411765 361.411765 361.411765h184.014581V692.705882H294.530793l337.939795-361.411764 337.939796 361.411764H726.132229v331.294118H933.647059v-1.555371c185.470975-15.299199 331.294118-170.426291 331.294117-359.856394 0-168.969898-116.101408-310.367302-272.769732-349.958575z"
              p-id="2416"
              fill="#c61200"
            />
          </svg>
          <!-- <span>DIY</span> -->
        </div>
        <!-- 本地视频 -->
        <div
          v-if="isOpenLocalFile && jurisdiction.functions['plv']"
          class="open column"
          title="打开本地视频"
          @click="onOepnLocalFile"
        >
          <input
            id="sourceFile"
            ref="localFileInput"
            multiple="false"
            accept=".mp4"
            type="file"
            style="display: none"
            @change="handleFileChange"
          />
          <img src="../assets/images/home/上传视频.png" style="width:18px;" />
          <!-- <span>offical</span> -->
        </div>
        <!-- 公告 -->
        <div v-if="jurisdiction.functions['n']" class="nav">
          <el-popover placement="bottom" width="300" trigger="click">
            <img slot="reference" src="../assets/images/home/通知.png" style="width:14px;" />
            <div class="notice column">
              <span class="title">公告</span>
              <el-scrollbar style="height:400px;" wrap-style="overflow-x:hidden;">
                <span class="content" v-html="setData.notice"></span>
              </el-scrollbar>
            </div>
          </el-popover>
        </div>
        <!-- 头像 -->
        <div class="avtor" @click="setVisible=true;">
          <span class="el-dropdown-link">
            <svg
              v-if="head==null || head==''"
              t="1634796550171"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="14770"
              width="30"
              height="30"
            >
              <path
                d="M511.626 1.896C229.572 1.896 0.927 230.541 0.927 512.595c0 282.055 228.645 510.699 510.699 510.699s510.698-228.645 510.698-510.699S793.68 1.896 511.626 1.896z m0 69.641c243.606 0 441.058 197.474 441.058 441.058 0 87.347-25.392 168.762-69.194 237.271-73.419-77.609-170.944-132.204-280.597-151.829 70.004-33.755 118.404-105.164 118.404-188.066 0-115.388-93.535-208.922-208.923-208.922S303.452 294.583 303.452 409.97c0 82.902 48.399 154.311 118.403 188.066-110.093 19.704-207.96 74.661-281.479 152.77-44.177-68.704-69.808-150.465-69.808-238.211 0-243.584 197.496-441.058 441.058-441.058z"
                p-id="14771"
                fill="#a9a9a9"
              />
            </svg>

            <div v-else class="head">
              <el-image class="image" :src="head" fit="fit" />
            </div>
          </span>
        </div>
      </div>
    </el-header>
    <!-- 菜单 -->
    <el-container style="height: calc(100% - 45px);">
      <el-aside v-if="!isScreen" :width="asideWidth?'200px':'0px'" class="menu">
        <div class="column">
          <div
            v-if=" jurisdiction.menus['ov']"
            class="row menu-row"
            :class="typeSelect==1?'selected':''"
            @click="onType(1)"
          >
            <img
              v-if="typeSelect==1"
              src="../assets/images/home/在线视频-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/在线视频.png" style="width:12px;height: 12px;" />
            <label>在线视频</label>
          </div>
          <div
            v-if="jurisdiction.menus['ar']"
            class="row menu-row"
            :class="typeSelect==10?'selected':''"
            @click="onType(10)"
          >
            <img
              v-if="typeSelect==10"
              src="../assets/images/home/有声阅读-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/有声阅读.png" style="width:12px;height: 12px;" />
            <label>有声阅读</label>
          </div>
          <div
            v-if="jurisdiction.menus['pem']"
            class="row menu-row"
            :class="typeSelect==2?'selected':''"
            @click="onType(2)"
          >
            <img
              v-if="typeSelect==2"
              src="../assets/images/home/会练英语手册-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/会练英语手册.png" style="width:12px;height: 12px;" />
            <label>会练英语手册</label>
          </div>
          <div
            v-if="jurisdiction.menus['nwb']"
            class="row menu-row"
            :class="typeSelect==3?'selected':''"
            @click="onType(3)"
          >
            <img
              v-if="typeSelect==3"
              src="../assets/images/home/生词本-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/生词本.png" style="width:12px;height: 12px;" />
            <label>生词本</label>
          </div>
          <div
            v-if="jurisdiction.menus['wfs']"
            class="row menu-row"
            :class="typeSelect==4?'selected':''"
            @click="onType(4)"
          >
            <img
              v-if="typeSelect==4"
              src="../assets/images/home/词频统计-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/词频统计.png" style="width:12px;height: 12px;" />
            <label>词频统计</label>
          </div>
          <div
            v-if="jurisdiction.menus['r']"
            class="row menu-row"
            :class="typeSelect==5?'selected':''"
            @click="onType(5)"
          >
            <img
              v-if="typeSelect==5"
              src="../assets/images/home/阅读-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/阅读.png" style="width:12px;height: 12px;" />
            <label>阅读</label>
          </div>
          <div
            v-if="jurisdiction.menus['book']"
            class="row menu-row"
            :class="typeSelect==7?'selected':''"
            @click="onType(7)"
          >
            <img
              v-if="typeSelect==7"
              src="../assets/images/home/阅读-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/阅读.png" style="width:12px;height: 12px;" />
            <label>绘本阅读器</label>
          </div>
          <div
            v-if="jurisdiction.menus['eq']"
            class="row menu-row"
            :class="typeSelect==6?'selected':''"
            @click="onType(6)"
          >
            <img
              v-if="typeSelect==6"
              src="../assets/images/home/例句搜索-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/例句搜索.png" style="width:12px;height: 12px;" />
            <label>例句查询</label>
          </div>
          <div
            v-if="jurisdiction.menus['playlog']"
            class="row menu-row"
            :class="typeSelect==8?'selected':''"
            @click="onType(8)"
          >
            <img
              v-if="typeSelect==8"
              src="../assets/images/home/学习记录-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/学习记录.png" style="width:12px;height: 12px;" />
            <label>学习记录</label>
          </div>
          <div
            v-if="jurisdiction.menus['playlog']"
            class="row menu-row"
            :class="typeSelect==9?'selected':''"
            @click="onType(9)"
          >
            <img
              v-if="typeSelect==9"
              src="../assets/images/home/视频收藏-1.png"
              style="width:12px;height: 12px;"
            />
            <img v-else src="../assets/images/home/视频收藏.png" style="width:12px;height: 12px;" />
            <label>视频收藏</label>
          </div>
        </div>
      </el-aside>
      <transition name="fade-transform" mode="out-in">
        <router-view style="height: 100%;width: 100%;" />
      </transition>
    </el-container>
    <!-- 自定义视频、字幕 -->
    <el-dialog
      :visible="isCustomVideoCaptions"
      title="自定义视频、字幕"
      width="300px"
      @close="isCustomVideoCaptions=false"
    >
      <div class="column" style="padding: 0px 0px 30px 0px;">
        <!-- 视频 -->
        <div class="column">
          <input
            id="sourceFile"
            ref="videoFileInput"
            multiple="false"
            accept=".mp4"
            type="file"
            style="display: none"
            @change="onVideoFileChange"
          />
          <el-button
            size="small"
            icon="el-icon-upload"
            type="primary"
            @click="onVideoFileClick()"
          >选择视频</el-button>
          <div slot="tip" class="el-upload__tip column">
            <span>选择.MP4或.MP3文件</span>
            <span v-if="videoFile">{{ videoFile.name }}</span>
          </div>
        </div>
        <!-- 字幕 -->
        <div class="column">
          <input
            id="subtitleFile"
            ref="subtitleFileInput"
            multiple="false"
            accept=".srt"
            type="file"
            style="display: none"
            @change="onSubtitleFileChange"
          />
          <el-button
            size="small"
            icon="el-icon-upload"
            type="primary"
            @click="onSubtitleFileClick()"
          >选择字幕</el-button>
          <div slot="tip" class="el-upload__tip column">
            <span>选择.srt字幕文件（请确保中英双语，英上中下）</span>
            <span v-if="subtitleFile">{{ subtitleFile.name }}</span>
          </div>
        </div>
        <div slot="footer" class="cvc-dialog-footer">
          <el-button type="small" @click="isCustomVideoCaptions = false;onCustomVideoCaptions()">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 新版设置 -->
    <div v-if="setVisible" class="set-win" @click="setVisible=frame;">
      <div class="win column" @click.stop>
        <div class="title">会练影音 - 设置</div>
        <div class="row">
          <!-- 快捷键 -->
          <el-scrollbar class="keyboard page-component__scroll">
            <div label="快捷键">
              <div class="column">
                <span class="hepl-tooltip">键盘快捷键</span>
                <span class="hepl-info">【ESC】退出全屏</span>
                <span v-if="jurisdiction.functions['kti']" class="hepl-info">【S or ↓】一键激发</span>
                <span v-if="jurisdiction.functions['bf']" class="hepl-info">【B】模糊化</span>
                <span v-if="jurisdiction.functions['sc']" class="hepl-info">【W or ↑】单句循环</span>
                <span v-if="jurisdiction.functions['av']" class="hepl-info">【N】添加笔记</span>
                <!-- <span v-if="userInfo.grade !== 1.1" class="hepl-info">【M】打开联想视频</span> -->
                <span class="hepl-info">【SPACE or 鼠标左键】播放/暂停</span>
                <span v-if="jurisdiction.functions['tsfar']" class="hepl-info">【&lt;】后退2秒</span>
                <span v-if="jurisdiction.functions['tsfar']" class="hepl-info">【&gt;】前进2秒</span>
                <span v-if="jurisdiction.functions['mc']" class="hepl-info">【+】加0.1倍</span>
                <span v-if="jurisdiction.functions['mc']" class="hepl-info">【-】减0.1倍</span>
                <span v-if="jurisdiction.functions['mc']" class="hepl-info">【0】正常倍数</span>
                <span v-if="jurisdiction.functions['jfns']" class="hepl-info">【A or ←】上一句</span>
                <span v-if="jurisdiction.functions['jfns']" class="hepl-info">【D or →】下一句</span>
                <span v-if="jurisdiction.functions['hts']" class="hepl-info">【H】隐藏字幕</span>
                <span v-if="jurisdiction.functions['cp']" class="hepl-info">【C】字幕选词发音</span>
                <span v-if="jurisdiction.functions['vp']" class="hepl-info">【V】整段字幕发音</span>
                <span v-if="jurisdiction.functions['fs']" class="hepl-info">【中键单击】全屏</span>
                <span v-if="jurisdiction.functions['abc']" class="hepl-info">【[】AB点开始</span>
                <span v-if="jurisdiction.functions['abc']" class="hepl-info">【]】AB点结束</span>
                <span class="hepl-info">【K】上一个视频</span>
                <span class="hepl-info">【L】下一个视频</span>
                <span class="hepl-tooltip">鼠标操作</span>
                <span class="hepl-info">左键查词、暂停、选择</span>
                <span v-if="jurisdiction.functions['vp']" class="hepl-info">右键查词、挖空、取消挖空</span>
                <span v-if="jurisdiction.functions['fs']" class="hepl-info">中间全屏</span>
                <span v-if="jurisdiction.functions['jfns']" class="hepl-info">滚轮前推上一句</span>
                <span v-if="jurisdiction.functions['jfns']" class="hepl-info">滚轮后推下一句</span>
              </div>
            </div>
          </el-scrollbar>
          <div class="column" style="width:70%">
            <div class="row nickname">
              <div v-if="jurisdiction.functions['is']" class="nickname-info">
                <span>输入统计</span>
              </div>
              <div v-if="jurisdiction.functions['sv']" class="nickname-info">
                <span>{{ nickname }}</span>
                <span
                  style="font-size:12px;color: #797978;font-weight: normal;"
                >(过期时间：{{ setData.validityTime?setData.validityTime:'永久' }})</span>
              </div>
            </div>
            <!-- 输入统计 -->
            <div v-if="jurisdiction.functions['is']" class="statistics">
              <div class="border">
                <div class="row statistics-study">
                  <span class="rightdownline">昨日</span>
                  <span class="rightdownline">本周</span>
                  <span class="rightdownline">本月</span>
                  <span class="downline">累计</span>
                </div>
                <div class="row statistics-study">
                  <!-- {{ Math.floor(learningTime/60) }}小时{{ learningTime- Math.floor(learningTime/60)*60 }}分钟 -->
                  <span
                    class="rightdownline"
                  >{{ Math.floor(setData.yesterday/60/60) }}小时{{ (Math.floor(setData.yesterday/60)%60) }}分钟</span>
                  <span
                    class="rightdownline"
                  >{{ Math.floor(setData.week/60/60) }}小时{{ (Math.floor(setData.week/60)%60) }}分钟</span>
                  <span
                    class="rightdownline"
                  >{{ Math.floor(setData.month/60/60) }}小时{{ (Math.floor(setData.month/60)%60) }}分钟</span>
                  <span
                    class="downline"
                  >{{ Math.floor(setData.totalLearningTime/60/60) }}小时{{ (Math.floor(setData.totalLearningTime/60)%60) }}分钟</span>
                </div>
                <div class="row statistics-study">
                  <span class="rightdownline">连续输入</span>
                  <span class="rightdownline">{{ setData.contiDay }}天</span>
                  <span class="rightdownline">累计输入</span>
                  <span class="rightdownline">{{ setData.totalDay }}天</span>
                </div>
                <div class="row statistics-study">
                  <span class="rightline">平均输入</span>
                  <span
                    class="rightline"
                  >{{ (setData.totalLearningTime /60/60/setData.totalDay).toFixed(1) }}小时/天</span>
                  <span class="rightline"></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div class="column set">
              <el-scrollbar class="page-component__scroll" style="height: 203px;width: 100%;">
                <div style="display: flex;justify-content: center;align-items: center;">
                  <div style="width: 300px;">
                    <div v-if="jurisdiction.functions['sms']" class="row slip">
                      <el-switch
                        v-model="slip"
                        active-color="#ff4949"
                        inactive-color="#949494"
                        @change="onSlip"
                      />
                      <span>鼠标滑入字幕时，暂停播放</span>
                    </div>
                    <div class="row slip">
                      <el-switch
                        v-model="simplePause"
                        active-color="#ff4949"
                        inactive-color="#949494"
                        @change="onSimplePause"
                      />
                      <span>单句播放暂停</span>
                    </div>
                    <div v-if="jurisdiction.functions['cc']" class="row slip">
                      <el-switch
                        v-model="showZh"
                        active-color="#ff4949"
                        inactive-color="#949494"
                        @change="onShowZh"
                      />
                      <span>显示中文</span>
                    </div>
                    <div v-if="jurisdiction.functions['odc']" class="row slip">
                      <el-switch
                        v-model="isOpenYouDao"
                        active-color="#ff4949"
                        inactive-color="#949494"
                        @change="onOpenYouDao"
                      />
                      <span>在线词典</span>
                    </div>
                    <div v-if="jurisdiction.functions['odc']" class="row slip">
                      <el-switch
                        v-model="isHollow"
                        active-color="#ff4949"
                        inactive-color="#949494"
                        @change="onHollow"
                      />
                      <span>挖空显示<span style="font-size: 12px;color: #a1a1a1;">(下句生效)</span></span>
                    </div>
                    <!-- <div v-if="jurisdiction.functions['ar']" class="row slip">
                      <el-switch
                        v-model="isAutorePlay"
                        active-color="#ff4949"
                        inactive-color="#949494"
                        @change="onAutorePlay"
                      />
                      <span>自动重播</span>
                    </div> -->
                    <div v-if="jurisdiction.functions['imoe']" class="row slip">
                      <el-select v-model="payRatio" style="z-index:3000;" @change="onPayRatio">
                        <el-option label="0.5x" value="0.4" />
                        <el-option label="0.6x" value="0.5" />
                        <el-option label="0.7x" value="0.6" />
                        <el-option label="0.8x" value="0.7" />
                        <el-option label="0.9x" value="0.8" />
                      </el-select>
                      <span>激发初始倍率</span>
                    </div>
                    <el-input
                      v-if="jurisdiction.functions['ss']"
                      v-model="enFont"
                      placeholder="请输入字号"
                      style="margin-bottom: 10px;"
                      @input="onEhInput"
                    >
                      <template slot="prepend">英文</template>
                      <template slot="append">字幕大小</template>
                    </el-input>
                    <el-input
                      v-if="jurisdiction.functions['ss']"
                      v-model="zhFont"
                      placeholder="请输入字号"
                      style="margin-bottom: 10px;"
                      @input="onZhInput"
                    >
                      <template slot="prepend">中文</template>
                      <template slot="append">字幕大小</template>
                    </el-input>
                    <el-input
                      v-model="tele"
                      placeholder="请输入电话号码"
                      style="margin-bottom: 10px;"
                      :disabled="true"
                    >
                      <template slot="prepend">电话</template>
                    </el-input>
                    <el-input v-model="isWeChat" style="margin-bottom: 10px;" :disabled="true">
                      <svg
                        slot="prepend"
                        t="1635216050037"
                        class="icon"
                        viewBox="0 0 1309 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2420"
                        width="16"
                        height="16"
                        style="margin-top:4px;width:24px;"
                      >
                        <path
                          d="M1147.26896 912.681417l34.90165 111.318583-127.165111-66.823891a604.787313 604.787313 0 0 1-139.082747 22.263717c-220.607239 0-394.296969-144.615936-394.296969-322.758409s173.526026-322.889372 394.296969-322.889372C1124.219465 333.661082 1309.630388 478.669907 1309.630388 656.550454c0 100.284947-69.344929 189.143369-162.361428 256.130963zM788.070086 511.869037a49.11114 49.11114 0 0 0-46.360916 44.494692 48.783732 48.783732 0 0 0 46.360916 44.494693 52.090549 52.090549 0 0 0 57.983885-44.494693 52.385216 52.385216 0 0 0-57.983885-44.494692z m254.985036 0a48.881954 48.881954 0 0 0-46.09899 44.494692 48.620028 48.620028 0 0 0 46.09899 44.494693 52.385216 52.385216 0 0 0 57.983886-44.494693 52.58166 52.58166 0 0 0-57.951145-44.494692z m-550.568615 150.018161a318.567592 318.567592 0 0 0 14.307712 93.212943c-14.307712 1.080445-28.746387 1.768001-43.283284 1.768001a827.293516 827.293516 0 0 1-162.394168-22.296458l-162.001279 77.955749 46.328175-133.811485C69.410411 600.858422 0 500.507993 0 378.38496 0 166.683208 208.689602 0 463.510935 0c227.908428 0 427.594322 133.18941 467.701752 312.379588a427.463358 427.463358 0 0 0-44.625655-2.619261c-220.24709 0-394.100524 157.74498-394.100525 352.126871zM312.90344 189.143369a64.270111 64.270111 0 0 0-69.803299 55.659291 64.532037 64.532037 0 0 0 69.803299 55.659292 53.694846 53.694846 0 0 0 57.852923-55.659292 53.465661 53.465661 0 0 0-57.852923-55.659291z m324.428188 0a64.040926 64.040926 0 0 0-69.574114 55.659291 64.302852 64.302852 0 0 0 69.574114 55.659292 53.694846 53.694846 0 0 0 57.951145-55.659292 53.465661 53.465661 0 0 0-57.951145-55.659291z"
                          p-id="2421"
                          fill="#8a8a8a"
                        />
                      </svg>
                      <el-button
                        v-if="isWeChat==='未绑定'"
                        slot="append"
                        style="color: rgb(2, 163, 83)"
                        @click="onBindingWeChat"
                      >绑定</el-button>
                    </el-input>
                    <el-collapse v-if="jurisdiction.functions['esc']">
                      <el-collapse-item title="例句控制" name="1">
                        <label style="font-size:12px;font-weight: bold;">内容控制</label>
                        <el-radio-group
                          v-model="example"
                          style="margin-bottom: 10px;"
                          @change="onExampleChange"
                        >
                          <el-radio :label="1">不隐藏</el-radio>
                          <el-radio :label="2">隐藏中文</el-radio>
                          <el-radio :label="3">隐藏英文</el-radio>
                          <el-radio :label="4">隐藏中英</el-radio>
                        </el-radio-group>
                        <label style="font-size:12px;font-weight: bold;">搜索控制</label>
                        <el-checkbox-group
                          v-model="levelSelect"
                          size="mini"
                          style="margin-bottom: 10px;"
                          @change="onLevelChange"
                        >
                          <el-checkbox-button v-for="l in level" :key="l" :label="l">{{ l }}</el-checkbox-button>
                        </el-checkbox-group>
                        <label style="font-size:12px;font-weight: bold;">排序控制</label>
                        <el-checkbox-group v-model="sortSelect" size="mini" @change="onSortChange">
                          <el-checkbox-button v-for="s in sort" :key="s" :label="s">{{ s }}</el-checkbox-button>
                        </el-checkbox-group>
                        <label style="font-size: 12px;color: #6b6868;">排序依次规则：{{ sortSelectText }}</label>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="close" @click="setVisible=false;">
          <i class="el-icon-close"></i>
        </div>
        <el-button
          type="danger"
          round
          class="esc"
          @click="onEscAccount('esc');setVisible=false;"
        >退出登录</el-button>
      </div>
    </div>
  </el-container>
</template>

<script>
import Vue from 'vue'
import SparkMD5 from 'spark-md5'
import { weChatLogin } from '../apis/sys'
import { setPage, bindingWeChat, setRoute } from '../apis/user'
import { search } from '@/apis/video'
import router from '@/router'

export default {
  data() {
    return {
      jurisdiction: {}, // 权限
      asideWidth: true,
      isScreen: false,
      userInfo: {}, // 用户信息
      uid: '',
      head: '',
      nickname: '',
      tele: '',
      learningTime: 0,
      zhFont: 18,
      enFont: 20,
      slip: true,
      simplePause: false,
      showZh: false,
      isOpenLocalFile: false,
      setVisible: false,
      isWeChat: '未绑定',
      isOpenYouDao: true,
      isHollow: true,
      isAutorePlay: false,
      setData: {},
      payRatio: '0.4',
      isCustomVideoCaptions: false,
      videoFile: {},
      subtitleFile: {},
      example: 4,
      level: ['初级', '初中级', '中级', '中高级', '高级'],
      levelSelect: ['初级', '初中级', '中级', '中高级', '高级'],
      sort: ['学习时间', '例句长度', '复杂程度'],
      sortSelect: [],
      sortSelectText: '默认随机排序',
      typeSelect: 1,
      route: 'China',
      searchVideoList: [], // 搜索视频集合
      searchHeight: 0,
      search: '', // 搜索
      searchShow: false,
      visible: false
    }
  },
  async created() {
    var that = this
    Vue.$screen = function(iss) {
      if (iss === undefined) that.isScreen = !that.isScreen
      else that.isScreen = iss
    }
    Vue.$openLocalFile = function(iss) {
      if (iss === undefined) that.isOpenLocalFile = !that.isOpenLocalFile
      else that.isOpenLocalFile = iss
    }
    Vue.$setUserInfo = function() {
      var userInfo = JSON.parse(localStorage.getItem('user'))
      that.uid = userInfo.id
      that.head = userInfo.head
      that.tele = userInfo.tele
      that.nickname = userInfo.nickname
      that.learningTime = (userInfo.learningTime / 60).toFixed(0)
      that.isWeChat = userInfo.isWeChat ? '已绑定' : '未绑定'
      that.userInfo = userInfo
    }
    Vue.$setPage = function() {
      setPage().then(res => {
        that.setData = res.data
      })
    }
    Vue.$day = function() {
      that.setData.day += 5
    }
    Vue.$OnOepnLocalFile = function() {
      console.log(1)
      that.onOepnLocalFile()
    }
    if (localStorage.getItem('user') !== null) {
      var userInfo = JSON.parse(localStorage.getItem('user'))
      this.uid = userInfo.id
      this.head = userInfo.head
      this.tele = userInfo.tele
      this.nickname = userInfo.nickname
      this.learningTime = (userInfo.learningTime / 60).toFixed(0)
      this.isWeChat = userInfo.isWeChat ? '已绑定' : '未绑定'
      this.userInfo = userInfo
    }
    if (localStorage.getItem('zhSize') != null) {
      this.zhFont = localStorage.getItem('zhSize')
    }
    if (localStorage.getItem('enSize') != null) {
      this.enFont = localStorage.getItem('enSize')
    }
    if (localStorage.getItem('slip') != null) {
      this.slip = localStorage.getItem('slip') === 'true'
    }
    if (localStorage.getItem('simplePause') != null) {
      this.simplePause = localStorage.getItem('simplePause') === 'true'
    }
    if (localStorage.getItem('isOpenYouDao') != null) {
      this.isOpenYouDao = localStorage.getItem('isOpenYouDao') === 'true'
    }
    if (localStorage.getItem('isHollow') != null) {
      this.isHollow = localStorage.getItem('isHollow') === 'true'
    }
    if (localStorage.getItem('isAutorePlay') != null) {
      this.isAutorePlay = localStorage.getItem('isAutorePlay') === 'true'
    }
    if (localStorage.getItem('payRatio') != null) {
      this.payRatio = localStorage.getItem('payRatio')
    }
    if (localStorage.getItem('showZh') != null) {
      this.showZh = localStorage.getItem('showZh') === 'true'
    }
    if (localStorage.getItem('example') != null) {
      this.example = localStorage.getItem('example') * 1
    }
    if (localStorage.getItem('level') != null) {
      this.levelSelect = JSON.parse(localStorage.getItem('level'))
    }
    if (localStorage.getItem('sort') != null) {
      this.sortSelect = JSON.parse(localStorage.getItem('sort'))
      if (this.sortSelect.length > 0) {
        this.sortSelectText = this.sortSelect.join('->')
      } else {
        this.sortSelectText = '默认随机排序'
      }
    }
    if (JSON.parse(localStorage.getItem('user')) === null) {
      router.push('/login')
      return
    }
    // 刷新重新定位菜单
    switch (this.$route.name) {
      case 'Index':
        this.typeSelect = 1
        break
      case 'Notebook':
        this.typeSelect = 3
        break
      case 'Statistics':
        this.typeSelect = 4
        break
      case 'Reading':
        this.typeSelect = 5
        break
      case 'Sentence':
        this.typeSelect = 6
        break
      case 'Cartoon':
        this.typeSelect = 7
        break
      case 'Palylog':
        this.typeSelect = 8
        break
      case 'Audioreading':
      case 'Readingplayer':
        this.typeSelect = 10
        break
    }
    this.jurisdiction = JSON.parse(localStorage.getItem('user')).jurisdiction

    // 非绑定方式 微信登录
    var isbinding = this.$route.query.isbinding
    // 有微信登录/微信绑定 参数时
    if (isbinding !== undefined) {
      var code = this.$route.query.code
      if (isbinding !== 'False') {
        if (code) {
          bindingWeChat({ code: code }).then(res => {
            if (res.code === 200) {
              var userInfo = JSON.parse(localStorage.getItem('user'))
              userInfo.isWeChat = '已绑定'
              localStorage.setItem('user', JSON.stringify(userInfo))
              Vue.$setUserInfo()
              return
            }
          })
        }
      }
    }
    that.searchHeight = document.documentElement.clientHeight - 220
  },
  mounted() {
    var that = this
    var isChrome = navigator.userAgent.toLowerCase().match(/chrome/)
    if (!isChrome) {
      this.$message({
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        message:
          '推荐使用谷歌浏览器<a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">点击下载</a>',
        type: 'warning'
      })
    }
    if (this.uid !== '') {
      setPage().then(res => {
        this.setData = res.data
      })
    }
    var usriInfo = JSON.parse(localStorage.getItem('user'))
    this.route = usriInfo.route ? usriInfo.route : 'China'

    // 窗口变化
    window.onresize = function() {
      that.searchHeight = document.documentElement.clientHeight - 220
    }
  },
  methods: {
    onHome() {
      if (this.$route.name !== 'Index') {
        this.$router.push({
          name: 'Index'
        })
      }
    },
    // 退出账号
    onEscAccount(command) {
      switch (command) {
        case 'set':
          this.setVisible = !this.setVisible
          break
        case 'esc':
          Vue.$conf.userInfo = {}
          localStorage.removeItem('Tele')
          localStorage.removeItem('LoginPass')
          localStorage.removeItem('user')

          if (this.$route.name !== 'login') {
            this.$router.push('/login')
          } else this.$router.go(0)
          break
      }
    },
    // 打开本地视频
    onOepnLocalFile() {
      this.$refs.localFileInput.dispatchEvent(new MouseEvent('click'))
    },
    // 处理文件改变
    async handleFileChange() {
      const that = this
      var files = this.$refs.localFileInput.files
      var fileList = []
      for (let index = 0; index < files.length; index++) {
        var data = await this.localityVideo(files[index])
        fileList.push(data)
      }
      that.$refs.localFileInput.value = '' // 清空文件
      if (that.$route.name === 'Index') {
        localStorage.setItem('localityvideo', JSON.stringify(fileList))
        that.$router.push({
          path: '/player',
          query: {
            g: '',
            v: '',
            locality: true
          }
        })
      }

      // files.array.forEach(element => {
      //   const spark = new SparkMD5.ArrayBuffer()
      //   const reader = new FileReader()
      //   const size = element.size
      //   const offset = 2 * 1024 * 1024
      //   // hash抽样: 第一个区块2M，中间区块取前中后各2个字节，最后区块数据全要
      //   const chunks = [element.slice(0, offset)]
      //   let cur = offset
      //   while (cur < size) {
      //     if (cur + offset >= size) {
      //       chunks.push(element.slice(cur, cur + offset))
      //     } else {
      //       const mid = cur + offset / 2
      //       const end = cur + offset
      //       chunks.push(element.slice(cur, cur + 2))
      //       chunks.push(element.slice(mid, mid + 2))
      //       chunks.push(element.slice(end - 2, end))
      //     }
      //     cur += offset
      //   }
      //   reader.readAsArrayBuffer(new Blob(chunks))
      //   reader.onload = function() {
      //     spark.append(this.result)
      //     var md5 = spark.end()
      //     that.$refs.localFileInput.value = '' // 清空文件
      //     if (that.$route.name === 'Index') {
      //       // that.$router.push({
      //       //   path: '/player',
      //       //   query: {
      //       //     g: '',
      //       //     v: '',
      //       //     locality: { src, md5 }
      //       //   }
      //       // })
      //     }
      //     console.log(` url: ${src},md5: ${md5}`)
      //   }
      // })
      // const spark = new SparkMD5.ArrayBuffer()
      // const reader = new FileReader()
      // const size = file.size
      // const offset = 2 * 1024 * 1024
      // // hash抽样: 第一个区块2M，中间区块取前中后各2个字节，最后区块数据全要
      // const chunks = [file.slice(0, offset)]
      // let cur = offset
      // while (cur < size) {
      //   if (cur + offset >= size) {
      //     chunks.push(file.slice(cur, cur + offset))
      //   } else {
      //     const mid = cur + offset / 2
      //     const end = cur + offset
      //     chunks.push(file.slice(cur, cur + 2))
      //     chunks.push(file.slice(mid, mid + 2))
      //     chunks.push(file.slice(end - 2, end))
      //   }
      //   cur += offset
      // }
      // reader.readAsArrayBuffer(new Blob(chunks))
      // reader.onload = function() {
      //   spark.append(this.result)
      //   var md5 = spark.end()
      //   that.$refs.localFileInput.value = '' // 清空文件
      //   if (that.$route.name === 'Index') {
      //     that.$router.push({
      //       path: '/player',
      //       query: {
      //         g: '',
      //         v: '',
      //         locality: { src, md5 }
      //       }
      //     })
      //   }
      //   console.log(` url: ${src},md5: ${md5}`)
      // }
    },
    // 鼠标滑入字幕 是否暂停视频
    onSlip() {
      localStorage.setItem('slip', this.slip)
    },
    // 单句播放暂停
    onSimplePause() {
      localStorage.setItem('simplePause', this.simplePause)
    },
    // 关闭在线词典
    onOpenYouDao() {
      localStorage.setItem('isOpenYouDao', this.isOpenYouDao)
    },
    //  挖空显示
    onHollow() {
      localStorage.setItem('isHollow', this.isHollow)
    },
    // 自动重播
    onAutorePlay() {
      localStorage.setItem('isAutorePlay', this.isAutorePlay)
    },
    // 中文字幕事件
    onZhInput() {
      localStorage.setItem('zhSize', this.zhFont)
      if (Vue.$SubtitleSet) Vue.$SubtitleSet()
    },
    // 英文字幕事件
    onEhInput() {
      localStorage.setItem('enSize', this.enFont)
      if (Vue.$SubtitleSet) Vue.$SubtitleSet()
    },
    // 默认是否显示中文
    onShowZh() {
      localStorage.setItem('showZh', this.showZh)
      if (Vue.$ShowZh) Vue.$ShowZh()
    },
    // 绑定微信
    onBindingWeChat() {
      weChatLogin({ isbinding: true }).then(res => {
        window.location.href = res.data
      })
    },
    // 类型选择 菜单
    onType(type) {
      // if (type === this.typeSelect) {
      //   return
      // }
      this.typeSelect = type
      if (type === 3) {
        router.push({
          name: 'Notebook'
        })
        return
      }
      if (type === 4) {
        router.push({
          name: 'Statistics'
        })
        return
      }
      if (type === 5) {
        router.push({
          name: 'Reading'
        })
        return
      }
      if (type === 6) {
        router.push({
          name: 'Sentence'
        })
        return
      }
      if (type === 7) {
        router.push({
          name: 'Cartoon'
        })
        return
      }
      if (type === 8) {
        router.push({
          name: 'Palylog'
        })
        return
      }
      if (type === 9) {
        router.push({
          name: 'AvCollect'
        })
        return
      }
      if (type === 10) {
        router.push({
          name: 'Audioreading'
        })
        return
      }
      if (window.location.href.indexOf('index') === -1) {
        router.push({
          name: 'Index',
          params: { type: type }
        })
      } else {
        Vue.$type(type)
      }
    },
    // 激发默认开始倍率调整
    onPayRatio() {
      localStorage.setItem('payRatio', this.payRatio)
    },
    // 自定义选择视频点击事件
    onVideoFileClick() {
      this.$refs.videoFileInput.dispatchEvent(new MouseEvent('click'))
    },
    // 自定义选择视频变化事件
    onVideoFileChange() {
      this.videoFile = this.$refs.videoFileInput.files[0]
      // console.log(this.videoFile)
    },
    // 自定义选择字幕点击事件
    onSubtitleFileClick() {
      this.$refs.subtitleFileInput.dispatchEvent(new MouseEvent('click'))
    },
    // 自定义选择字幕变化事件
    onSubtitleFileChange() {
      this.subtitleFile = this.$refs.subtitleFileInput.files[0]
      // console.log(this.subtitleFile)
    },
    // 自定义视频、字幕
    onCustomVideoCaptions() {
      var that = this
      // console.log(this.videoFile)
      // console.log(this.subtitleFile)
      const src = window.URL
        ? window.URL.createObjectURL(this.videoFile)
        : window.webkitURL.createObjectURL(this.videoFile)
      const reader = new FileReader()
      reader.readAsText(this.subtitleFile, 'utf-8')
      var subtitle = ''
      reader.onload = function() {
        subtitle = reader.result
        if (that.$route.name === 'Index') {
          that.$router.push({
            path: '/player',
            query: {
              g: '',
              v: '',
              locality: { src, subtitle },
              isLocalVideoSubtitle: true
            }
          })
        } else if (that.$route.name === 'Player') {
          console.log('播放器')
          Vue.$handleLoadVideo({ url: src, subtitle, md5: '' })
        }
      }
    },
    // 例句控制
    onExampleChange(e) {
      localStorage.setItem('example', e)
      // console.log(e)
    },
    // 例句搜索控制
    onLevelChange(e) {
      localStorage.setItem('level', JSON.stringify(e))
    },
    // 例句排序控制
    onSortChange(e) {
      localStorage.setItem('sort', JSON.stringify(e))
      if (e.length > 0) {
        this.sortSelectText = e.join('->')
      } else {
        this.sortSelectText = '默认随机排序'
      }
    },
    // 线路选择
    onRouteChange() {
      console.log(this.route)
      setRoute({ route: this.route }).then(res => {
        var usriInfo = JSON.parse(localStorage.getItem('user'))
        usriInfo.route = this.route
        localStorage.setItem('user', JSON.stringify(usriInfo))
        location.reload()
      })
    },
    onMenuShow() {
      if (Vue.$menuShow) {
        Vue.$menuShow(this.asideWidth)
      }
    },
    // 搜索视频
    onSearch() {
      if (this.search) {
        var jurisdiction = JSON.parse(localStorage.getItem('user')).jurisdiction
        search({ key: this.search, names: jurisdiction.videos }).then(res => {
          this.searchVideoList = res.data
        })
      }
    },
    // 视频点击事件
    onVideoClick(id, grouping) {
      if (this.$route.name !== 'Player') {
        this.$router.push({
          path: '/player',
          query: {
            v: id
          }
        })
      } else {
        Vue.$playeVideo(id)
      }
    },
    // 搜索框获取焦点时
    onSearchFocus() {
      document.onkeydown = {}
    },
    // 搜索框失去焦点时
    onSearchBlur() {
      if (this.$route.name === 'Player') {
        Vue.$onkeydown()
      }
    },
    // 本地视频批量选择
    localityVideo(file) {
      return new Promise((resolve, reject) => {
        const spark = new SparkMD5.ArrayBuffer()
        const reader = new FileReader()
        const size = file.size
        const offset = 2 * 1024 * 1024
        // hash抽样: 第一个区块2M，中间区块取前中后各2个字节，最后区块数据全要
        const chunks = [file.slice(0, offset)]
        let cur = offset
        while (cur < size) {
          if (cur + offset >= size) {
            chunks.push(file.slice(cur, cur + offset))
          } else {
            const mid = cur + offset / 2
            const end = cur + offset
            chunks.push(file.slice(cur, cur + 2))
            chunks.push(file.slice(mid, mid + 2))
            chunks.push(file.slice(end - 2, end))
          }
          cur += offset
        }
        reader.readAsArrayBuffer(new Blob(chunks))
        reader.onload = function() {
          spark.append(this.result)
          var md5 = spark.end()
          // 生成临时地址
          const src = window.URL
            ? window.URL.createObjectURL(file)
            : window.webkitURL.createObjectURL(file)
          var data = {
            src,
            md5,
            name: file.name
          }
          resolve(data)
        }
      })
    }
  }
}
</script>
<style>
.el-radio {
  margin-top: 5px;
}
.el-radio__label {
  font-size: 12px !important;
}
</style>

<style lang="less">
@backgroud-color: #ffff;
.scrollbar {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.slip {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(102, 102, 102);
  span {
    margin-left: 10px;
  }
}
.layout {
  // margin: 20px;
  min-height: 0;
  background: #ffff;
  // border-radius: 10px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;
    .menu {
      width: 45px;
      height: 100%;
      background: #f5f5f5;
      // 左右上下居中
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        cursor: pointer;
      }
    }
    .logo {
      position: absolute;
      top: 5px;
      left: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        margin-bottom: -10px;
        width: 30px;
        height: 30px;
        // border-radius: 5px;
        margin-left: 20px;
      }
      span {
        margin-left: 10px;
        margin-top: 10px;
        color: white;
        font-size: 12px;
        font-weight: bold;
      }
      .type {
        cursor: pointer;
        margin-top: 10px;
        margin-left: 50px;
        div {
          cursor: pointer;
          margin-left: 20px;
          // 左右上下居中
          display: flex;
          justify-content: center;
          align-items: center;
        }
        span {
          cursor: pointer;
          margin-left: 5px;
          margin-top: 0px;
          font-size: 12px !important;
          font-weight: bold;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      .time {
        font-size: 12px;
        color: #333333;
        margin: 0px 30px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        .time-ioc {
          // 左右上下居中
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          font-weight: normal;
          svg {
            margin-right: 5px;
          }
          span {
            font-size: 12px;
            color: white;
          }
        }
      }
      .open {
        margin: 0px 30px 0 0;
        // 左右上下居中
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: white;
          font-size: 12px;
        }
      }
      .nav {
        margin: 0px 30px 0 0;
        // 左右上下居中
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: 18px;
          line-height: 30px;
          height: 18px;
        }
        span {
          color: white;
          font-size: 12px;
        }
      }
      .avtor {
        margin-top: 5px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head {
          border-color: white;
          border-width: 1px;
          border-style: solid;
          border-radius: 50%;
          // width: 20px !important;
          // height: 20px !important;
        }
        .image {
          border-radius: 4px;
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
  }
  &-main {
    background: @backgroud-color;
    flex: 1;
  }
}
.menu {
  img {
    margin-right: 10px;
    margin-left: 8px;
  }
  label {
    cursor: pointer;
  }
  .menu-row {
    font-size: 12px;
    cursor: pointer;
    // 左右上下居中
    display: flex;
    // justify-content: center;
    align-items: center;
    padding: 10px 20px;
    img {
      margin-left: 0px;
    }
  }
  .selected {
    background: #b62b1a;
    color: white;
  }
}

.layout-screen {
  margin: 0px !important;
  border-radius: 0px;
}
.hepl-tooltip {
  font-size: 14px;
  color: #c61200;
  font-weight: bold;
  margin-bottom: 4px;
}
.hepl-info {
  font-size: 12px;
  margin-bottom: 4px;
}
.head {
  border-color: white;
  border-width: 2px;
  border-style: solid;
}
.set-win {
  position: absolute;
  width: 100%;
  height: 100%;
  // 左右上下居中
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.144);
  z-index: 3000;
  .title {
    padding: 10px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: bold;
    border-width: 0px 0px 1px 0px;
    border-color: rgb(231, 231, 231);
    border-style: solid;
    background: #c61200;
    border-radius: 10px 10px 0px 0px;
  }
  .keyboard {
    padding-left: 10px;
    overflow-x: hidden;
    height: 460px;
    width: 250px;
  }
  .win {
    position: relative;
    width: 800px;
    height: 530px;
    background: white;
    border-radius: 10px;
    // border-width: 1px;
    // border-color: rgb(197, 197, 197);
    // border-style: solid;
    box-shadow: 0px 0px 20px #414141;
    .nickname {
      font-size: 14px;
      font-weight: bold;
      height: 50px;
      border-width: 0px 0px 1px 0px;
      border-color: rgb(231, 231, 231);
      border-style: solid;
      margin: 10px;
      .nickname-info {
        margin-left: 65px;
        // width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .statistics {
      border-width: 0px 0px 1px 0px;
      border-color: rgb(231, 231, 231);
      border-style: solid;
      margin: 10px;
      // 左右上下居中
      display: flex;
      justify-content: center;
      align-items: center;
      .border {
        border-width: 1px;
        border-color: rgb(231, 231, 231);
        border-style: solid;
        width: auto;
        width: 400px;
        margin-bottom: 20px;
      }
      .statistics-study {
        span {
          // 左右上下居中
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 12px;
          font-weight: lighter;
          padding: 6px;
        }
      }
    }
    .set {
      // 左右上下居中
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .esc {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
  }
  .downline {
    border-width: 0px 0px 1px 0px;
    border-color: rgb(231, 231, 231);
    border-style: solid;
  }
  .rightdownline {
    border-width: 0px 1px 1px 0px;
    border-color: rgb(231, 231, 231);
    border-style: solid;
  }
  .rightline {
    border-width: 0px 1px 0px 0px;
    border-color: rgb(231, 231, 231);
    border-style: solid;
  }
}

.page-component__scroll .el-scrollbar__wrap {
  overflow-x: hidden;
  // color: #949494;
}

.notice {
  .title {
    font-size: 14px;
    margin-bottom: 5px;
    color: #252525;
  }
  .content {
    font-size: 12px;
    color: #414141;
  }
}
.cvc-dialog-footer {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.search {
  margin-right: 20px;
}
.video-list {
  margin: 0px 20px 40px 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  position: relative;
  // height: 150px;
  width: 230px;
  // margin: 0px 20px;
  background: white;
  border-radius: 10px;
}
.video-img {
  // position: absolute;
  height: 150px;
  width: 230px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  // top: 0xp;
  // left: 0px;
  // border-radius: 5px;
}
</style>
